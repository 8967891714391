/* eslint-disable no-unused-vars */
import { makeRequest } from '../Axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Dummy geoJson Data
const geoJson = [
  {
    type: 'Feature',
    properties: {
      fillColor: 'green',
      opacity: 1,
      color: 'black', //Outline color
      fillOpacity: 0.6
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [74.320503, 31.481449],
          [74.322241, 31.483018],

          [74.321136, 31.480762],
          [74.320627, 31.48122],
          [74.320503, 31.481449]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [74.320503, 31.481449],
          [74.322241, 31.483018],
          [74.323324, 31.482876],
          [74.324032, 31.481751],
          [74.32259, 31.480021],
          [74.321136, 31.480762],
          [74.320627, 31.48122],
          [74.320503, 31.481449]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: [74.3301, 31.4775]
    }
  },
  {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [
            [74.320503, 31.481449],
            [74.322241, 31.483018],
            [74.323324, 31.482876],
            [74.324032, 31.481751],

            [74.320503, 31.481449]
          ]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [74.320906, 31.483416],
          [74.324832, 31.483311],
          [74.32495, 31.482711],
          [74.324687, 31.482263],
          [74.324531, 31.481636],
          [74.320954, 31.482597],
          [74.320906, 31.483416]
        ]
      ]
    }
  },
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-71.33, 46.566],
              [-70.33, 46.566],
              [-70.33, 47.566],
              [-71.33, 47.566],
              [-71.33, 46.566]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-71.33, 46.566],
              [-70.33, 46.566],
              [-70.33, 47.566],
              [-71.33, 47.566],
              [-71.33, 46.566]
            ]
          ]
        }
      }
    ]
  }
];

const AR_BASE_URL = process.env.REACT_APP_ASSET_REGISTRY_BASE_URL || '';
const TERAPIPE_BASE_URL = process.env.REACT_APP_SERVER_URL || '';
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

const MapService = {
  getField: async (unique_id) => {
    try {
      const response = await makeRequest(SERVER_URL + `/fetch-field/${unique_id}`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: toast.POSITION.TOP_RIGHT });
      throw error?.response?.data;
    }
  },
  getCoordinates: async (scope) => {
    try {
      const response = await makeRequest(
        SERVER_URL + `/get-coordinates`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { scope }
      );
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: toast.POSITION.TOP_RIGHT });
      throw error?.response?.data;
    }
  },
  setName: async (unique_id, name) => {
    try {
      const response = await makeRequest(
        SERVER_URL + `/set-name/${unique_id}`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { name }
      );
      return response;
    } catch (error) {
      throw error?.response;
    }
  },
  deleteField: async (unique_id) => {
    try {
      const response = await makeRequest(SERVER_URL + `/delete-field/${unique_id}`, 'DELETE', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: toast.POSITION.TOP_RIGHT });
      throw error?.response?.data;
    }
  },
  saveField: async (geoId) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/geo-id`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { geo_id: geoId }
      );
      return response;
    } catch (error) {
      if (error?.response?.data?.message === 'Add Geo Id Error :::') {
        toast.error('Field already added', { position: toast.POSITION.TOP_RIGHT });
        console.log('ERROR ::: ', error);
        // return true;
      }
      // throw error?.response?.data;
    }
  },
  subscribeScope: async (scope_name, coords) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/add_user_scope`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { scope_name: scope_name, coordinates: coords }
      );
      return response;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`, { position: toast.POSITION.TOP_RIGHT });
      console.log('ERROR ::: ', error);
    }
  },
  unsubscribeScope: async (scope_name) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/remove_scope`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { scope_name: scope_name }
      );
      return response;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`, { position: toast.POSITION.TOP_RIGHT });
      console.log('ERROR ::: ', error);
    }
  },
  requestActivation: async (scope_name, coords) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/request-activation`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { scope: scope_name, coordinates: coords }
      );
      return response;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`, { position: toast.POSITION.TOP_RIGHT });
      console.log('ERROR ::: ', error);
    }
  },
  getScopeStatus: async () => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/get_user_scope`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`, { position: toast.POSITION.TOP_RIGHT });
      console.log('ERROR ::: ', error);
    }
  },
  plotData: async (geoId) => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/getWeatherForecast?geoid=${geoId}`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data['JSON Response'];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  soilData: async (geoId) => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/getSoil?geoid=${geoId}`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data['JSON Response'];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  tmfData: async (geoId) => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/getTMF?geoid=${geoId}`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  },
  NDVIData: async (geoId, startDate, endDate) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/getNDVIStats?geoid=${geoId}&start_date=${startDate}&end_date=${endDate}`,
        'GET',
        {
          'Access-Control-Allow-Origin': '*'
        }
      );
      return response.data['JSON Response'];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  NCEPData: async (geoId, startDate, endDate) => {
    try {
      let base_url =
        TERAPIPE_BASE_URL +
        `/getNCEPStats?geoid=${geoId}&start_date=${startDate}`;
      if (endDate) {
        base_url += `&end_date=${endDate}`;
      }
      const response = await makeRequest(base_url, "GET", {
        "Access-Control-Allow-Origin": "*",
      });
      return response.data["JSON Response"];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  NLDASData: async (geoId, startDate, endDate) => {
    try {
      let base_url =
        TERAPIPE_BASE_URL +
        `/getNLDASStats?geoid=${geoId}&start_date=${startDate}`;
      if (endDate) {
        base_url += `&end_date=${endDate}`;
      }
      const response = await makeRequest(base_url, "GET", {
        "Access-Control-Allow-Origin": "*",
      });
      return response.data["JSON Response"];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  NOAAData: async (geoId, startDate, endDate) => {
    try {
      let base_url =
        TERAPIPE_BASE_URL +
        `/getNOAAStats?geoid=${geoId}&start_date=${startDate}`;
      if (endDate) {
        base_url += `&end_date=${endDate}`;
      }
      const response = await makeRequest(
        base_url,
        'GET',
        {
          'Access-Control-Allow-Origin': '*'
        }
      );
      return response.data['JSON Response'];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  NOAAForecastedData: async (geoId, startDate, endDate) => {
    try {
      let baseUrl =
        TERAPIPE_BASE_URL +
        `/getNOAAForecastedStats?geoid=${geoId}&start_date=${startDate}`;
      if (endDate) {
        baseUrl += `&end_date=${endDate}`;
      }
      const response = await makeRequest(baseUrl, "GET", {
        "Access-Control-Allow-Origin": "*",
      });
      return response.data["JSON Response"];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  GHCNDData: async (geoId, startDate, endDate) => {
    try {
      let base_url =
      TERAPIPE_BASE_URL +
      `/getGHCNDStats?geoid=${geoId}&start_date=${startDate}`;
    if (endDate) {
      base_url += `&end_date=${endDate}`;
    }
      const response = await makeRequest(
        base_url,
        'GET',
        {
          'Access-Control-Allow-Origin': '*'
        }
      );
      return response.data['JSON Response'];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  AUSData: async (geoId, startDate, endDate) => {
    try {
      let base_url =
        TERAPIPE_BASE_URL +
        `/getAUSStats?geoid=${geoId}&start_date=${startDate}`;
      if (endDate) {
        base_url += `&end_date=${endDate}`;
      }
      const response = await makeRequest(base_url, "GET", {
        "Access-Control-Allow-Origin": "*",
      });
      return response.data["JSON Response"];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  AUSForecastedData: async (geoId, startDate, endDate) => {
    try {
      let baseUrl =
        TERAPIPE_BASE_URL +
        `/getAUSForecastedStats?geoid=${geoId}&start_date=${startDate}`;
      if (endDate) {
        baseUrl += `&end_date=${endDate}`;
      }
      const response = await makeRequest(baseUrl, "GET", {
        "Access-Control-Allow-Origin": "*",
      });
      return response.data["JSON Response"];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  NDVIDates: async (geoId) => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/getNDVIDatesForGeoid?geoid=${geoId}`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data['JSON Response'];
    } catch (error) {
      throw error?.response?.data;
    }
  },
  NDVIImg: async (geoId, imgDate, level) => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/getNDVIImg?geoid=${geoId}&dt=${imgDate}&level=${level}`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  },
  getFieldWithPoint: async (lat, lng, boundary_type, s2_index, domain) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/fetch-fields-for-a-point`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { latitude: lat, longitude: lng, s2_index, domain, boundary_type }
      );
      const data = response.data['Fetched fields'].map((e) => Object.values(e)).map((e) => e[0]['Geo JSON']);
      return { data, json: response.data };
    } catch (error) {
      throw error?.response?.data;
    }
  },
  getFieldWithRectangle: async (lats, lngs) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/fetch-bounding-box-fields`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { latitudes: lats, longitudes: lngs }
      );
      const data = response.data['message'].map((e) => Object.values(e)).map((e) => e[0]['Geo JSON']);
      return { data, json: response.data };
    } catch (error) {
      throw error?.response?.data;
    }
  },
  getOverlappingFields: async (wktData, resolution_level, threshold, domain, boundary_type, s2_index) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/fetch-overlapping-fields`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        {
          wkt: wktData,
          resolution_level,
          threshold,
          domain,
          boundary_type,
          s2_index
        }
      );
      const data = response.data['Matched Fields'].map((e) => Object.values(e)).map((e) => e[0]['Geo JSON']);
      return { data, json: response.data };
    } catch (error) {
      throw error?.response?.data;
    }
  },
  registerField: async (wktData, resolution_level, threshold, s2_index) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/register-field-boundary`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        { wkt: wktData, resolution_level, threshold, s2_index }
      );
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  },
  getDomains: async () => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/domains`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  },
  getPercentageOverlapFields: async (geojson1, geojson2) => {
    try {
      const response = await makeRequest(
        TERAPIPE_BASE_URL + `/get-percentage-overlap-two-fields`,
        'POST',
        {
          'Access-Control-Allow-Origin': '*'
        },
        {
          geo_id_field_1: geojson1,
          geo_id_field_2: geojson2
        }
      );
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  },
  getApplications: async () => {
    try {
      const response = await makeRequest(TERAPIPE_BASE_URL + `/applications`, 'GET', {
        'Access-Control-Allow-Origin': '*'
      });
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
};

export default MapService;
